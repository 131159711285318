.logo {
    padding: 15px 0;
    background: $logoBgColor;
    box-shadow: 0 0 3px rgba(0, 0, 0, .25);
    text-align: center;
    color: #565656;

    a {
        display: block;
        width: 130px;
        height: 34px;
        margin: 0 auto;
        background-image: url('images/mint_logo.svg');
        background-repeat: no-repeat;
        background-size: contain;
    }

    &.izum {
        background: #f5f5f5;

        a {
            display: inline-block;
            width: 64px;
            height: 64px;
            background-image: url('images/izum_logo.svg');
            vertical-align: middle;
        }

        a.logo-description {
            width: auto;
            height: auto;
            background: none;
            padding-left: 10px;
            text-align: left;
            line-height: 125%;
            color: #3C4044;
            font-size: 23px;
            font-weight: 600;
            text-decoration: none;
            text-transform: uppercase;
        }

        .title {
            margin-top: 10px;
        }

        @media screen and (max-width: 600px) {
            a {
                display: block;
            }
            
            a.logo-description {
                padding-left: 0;
                padding-top: 10px;
                text-align: center;
            }
        }
    }
}

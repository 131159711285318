@font-face {
    font-family: "Manrope";
    src: url("Manrope-Regular.woff");
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    font-variant: normal;
}

@font-face {
    font-family: "Manrope";
    src: url("Manrope-Light.woff");
    font-weight: 100;
    font-style: normal;
    font-stretch: normal;
    font-variant: normal;
}

@font-face {
    font-family: "Manrope";
    src: url("Manrope-Medium.woff");
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    font-variant: normal;
}

@font-face {
    font-family: "Manrope";
    src: url("Manrope-Semibold.woff");
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    font-variant: normal;
}

@font-face {
    font-family: "Manrope";
    src: url("Manrope-Bold.woff");
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    font-variant: normal;
}

.MuiTooltip-popper {

    .MuiTooltip-tooltip {
        border: 1px solid #dcdcdc;
        background: #f9f9f9;
        color: inherit;
        font-size: 0.8rem;
        font-style: italic;
        font-weight: normal;
        padding: 10px;
    }

    .MuiTooltip-arrow {
        color: #dcdcdc;
    }
}

.footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 50px;
    padding: 25px 0;
    background: $footerBgColor;
    color: $footerTextColor;

    @media screen and (max-width: 800px) {
        flex-direction: column;

        .cell {
            &.contacts {
                margin: 25px 0;
            }
        }
    }

    .cell {
        a {
            text-decoration: none;
            color: $footerTextColor;
        }

        .banner {
            display: block;
            width: 130px;
            height: 38px;
            background-image: url('images/mint_by_center.svg');
            background-size: contain;
            background-repeat: no-repeat;
        }

        &.contacts {
            font-weight: 600;
        }

        .app {
            display: inline-block;
            height: 40px;
            background-size: contain;
            background-repeat: no-repeat;

            &.appstore {
                width: 136px;
                background-image: url('images/appstore.png');
            }

            &.googleplay {
                width: 135px;
                background-image: url('images/googleplay.png');
                margin-left: 5px;
            }
        }
    }

    &.izum {
        background: #373b49;

        .banner-izum {
            a {
                display: inline-block;
                vertical-align: middle;
                color: #fff;
                text-transform: uppercase;
                font-weight: 600;
                line-height: 110%;

                &.banner-izum-logo {
                    width: 46px;
                    height: 46px;
                    background-image: url('images/izum_logo.svg');
                    background-repeat: no-repeat;
                    background-size: contain;
                    margin-right: 10px;
                }
            }
        }

        .cell {
            &.contacts {
                font-weight: normal;
                color: #dedef3;
                a {
                    color: #dedef3;
                }
            }
        }

        @media screen and (max-width: 1300px) {
            flex-direction: column;
    
            .cell {
                &.contacts {
                    padding: 0 25px;
                    margin: 25px 0;
                }
            }
        }
    }
}
